<template>
  <div class="change_bg">

    <Navbar></Navbar>
    <section>
      <div class="container">
        <div class="manage_link">
          <div class="m_link1">
            <router-link to="/">
              {{$store.state.language.lang[1]}}</router-link>
          </div>
          <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M0.106862 0.191111C0.277469 -0.0260253 0.591796 -0.0637446 0.808932 0.106862L7.80893 5.60686C7.92958 5.70165 8.00002 5.84659 8.00002 6.00002C8.00002 6.15345 7.92958 6.29839 7.80893 6.39318L0.808932 11.8932C0.591796 12.0638 0.277469 12.0261 0.106862 11.8089C-0.0637446 11.5918 -0.0260253 11.2775 0.191111 11.1069L6.69073 6.00002L0.191111 0.89318C-0.0260253 0.722574 -0.0637446 0.408246 0.106862 0.191111Z"
                    fill="#89899F"/>
            </svg>
          </div>
          <div class="m_link2">
            <router-link to="/">
              {{$store.state.language.lang[112]}}
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 col-12">
            <div class="m_body_title">
              <h2>  {{$store.state.language.lang[112]}}</h2>
            </div>
            <div class="form_selects">
              <form @submit.prevent="submits()">
                <div class="forms_select_groups">
                  <div class="input_ob">
                    <input type="text" v-model="searchs" :placeholder="$store.state.language.lang[142]">
                  </div>
                  <div class="input_select">
                    <a @click="openSelects()">
                      <div class="main_selects">
                        <div>
                          {{catename}}
                        </div>
                        <div>
                          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3374 0.460673C13.5743 0.61577 13.6154 0.901522 13.4293 1.09892L7.42932 7.46256C7.32591 7.57223 7.1678 7.63627 7.00042 7.63627C6.83304 7.63627 6.67493 7.57223 6.57152 7.46256L0.571523 1.09892C0.385407 0.901522 0.426554 0.615769 0.66343 0.460672C0.900305 0.305575 1.24321 0.339866 1.42932 0.537262L7.00042 6.446L12.5715 0.537262C12.7576 0.339866 13.1005 0.305576 13.3374 0.460673Z" fill="#01011C"/>
                          </svg>
                        </div>
                      </div>
                    </a>
                    <div class="selects_menus" :class="{'active':isselcts==true}">
                      <a>
                        <div class="drop_texts" @click="search_cate(item='null')">{{$store.state.language.lang[7]}}</div>
                      </a>
                      <a v-for="(item,index) in $store.state.heritage_category.results" :key="index">
                        <div class="drop_texts" @click="search_cate(item)">{{item.title}}</div>
                      </a>
                      <a>
                        <div class="drop_texts" @click="restoveratsiya(1)">{{$store.state.language.lang[166]}}</div>
                      </a>
                      <a>
                        <div class="drop_texts" @click="restoveratsiya(2)">{{$store.state.language.lang[167]}}</div>
                      </a>
                    </div>
                  </div>

                  <div class="input_select">
                    <a @click="openSelects1()">
                      <div class="main_selects">
                        <div>
                          {{region_name}}
                        </div>
                        <div>
                          <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3374 0.460673C13.5743 0.61577 13.6154 0.901522 13.4293 1.09892L7.42932 7.46256C7.32591 7.57223 7.1678 7.63627 7.00042 7.63627C6.83304 7.63627 6.67493 7.57223 6.57152 7.46256L0.571523 1.09892C0.385407 0.901522 0.426554 0.615769 0.66343 0.460672C0.900305 0.305575 1.24321 0.339866 1.42932 0.537262L7.00042 6.446L12.5715 0.537262C12.7576 0.339866 13.1005 0.305576 13.3374 0.460673Z" fill="#01011C"/>
                          </svg>
                        </div>
                      </div>
                    </a>
                    <div class="selects_menus" :class="{'active':isselcts1==true}">
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[113],index='null')">{{$store.state.language.lang[113]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[114],index=1)">{{$store.state.language.lang[114]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[115],index=2)">{{$store.state.language.lang[115]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[116],index=3)">{{$store.state.language.lang[116]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[117],index=4)">{{$store.state.language.lang[117]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[118],index=5)">{{$store.state.language.lang[118]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[119],index=6)">{{$store.state.language.lang[119]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[120],index=7)">{{$store.state.language.lang[120]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[121],index=8)">{{$store.state.language.lang[121]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[122],index=9)">{{$store.state.language.lang[122]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[123],index=10)">{{$store.state.language.lang[123]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[124],index=11)">{{$store.state.language.lang[124]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[125],index=12)">{{$store.state.language.lang[125]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[126],index=13)">{{$store.state.language.lang[126]}}</div>
                      </a>
                      <a >
                        <div class="drop_texts" @click="region(item=$store.state.language.lang[127],index=14)">{{$store.state.language.lang[127]}}</div>
                      </a>
                    </div>
                  </div>
                  <div class="input_button">
                    <button>{{$store.state.language.lang[139]}}</button>
                  </div>

                </div>
              </form>
            </div>
            <div class="news_card_child">
              <div class="row">
                <div class="col-xl-3 col-sm-6 col-12" v-for="(item,index) in $store.state.heritage_main.results" :key="index">
                  <router-link :to="'/object/'+item.id">
                    <div class="card_child_content">
                      <div class="news_cards">
                        <img :src="item.image" alt="">
                      </div>

                      <div class="news_card_child1 p-2">
                        <div>

                          <div class="card_cild_title">
                            {{item.title}}
                          </div>
                          <div class="card_date_child news_date">
                            {{item.parent_category}}
                          </div>
                          <div class="card_date_child news_date">
                            {{ item.address }}
                          </div>
                        </div>

                        <div class="arrow_icons">
                          <svg width="14" height="12" viewBox="0 0 14 12" fill="none"
                               xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M8.15061 0.885582C7.95535 0.69032 7.63877 0.69032 7.4435 0.885582C7.24824 1.08084 7.24824 1.39743 7.4435 1.59269L8.15061 0.885582ZM12.5579 5.99997L12.9114 6.35352C13.0052 6.25975 13.0579 6.13258 13.0579 5.99997C13.0579 5.86736 13.0052 5.74018 12.9114 5.64642L12.5579 5.99997ZM7.4435 10.4072C7.24824 10.6025 7.24824 10.9191 7.4435 11.1144C7.63877 11.3096 7.95535 11.3096 8.15061 11.1144L7.4435 10.4072ZM1.46289 5.49997C1.18675 5.49997 0.962891 5.72383 0.962891 5.99997C0.962891 6.27611 1.18675 6.49997 1.46289 6.49997V5.49997ZM12.5437 6.49997C12.8199 6.49997 13.0437 6.27611 13.0437 5.99997C13.0437 5.72383 12.8199 5.49997 12.5437 5.49997V6.49997ZM7.4435 1.59269L12.2043 6.35352L12.9114 5.64642L8.15061 0.885582L7.4435 1.59269ZM12.2043 5.64642L7.4435 10.4072L8.15061 11.1144L12.9114 6.35352L12.2043 5.64642ZM1.46289 6.49997H12.5437V5.49997H1.46289V6.49997Z"
                                fill="white"/>
                          </svg>
                        </div>
                      </div>

                      <div class="card_vectors">
                        <img src="../../assets/img/cr_vec.png" alt="">
                      </div>
                    </div>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="pagenation">
              <paginate
                  :page-count="$store.state.heritage_main.total_pages"
                  :page-range="3"
                  :margin-pages="2"
                  :click-handler="clickCallback"
                  :prev-text="'<'"
                  :next-text="'>'"
                  :container-class="'pagination'"
                  :page-class="'page-item'"
              >
              </paginate>
            </div>

          </div>
        </div>
      </div>
    </section>
    <Footer></Footer>
  </div>
</template>
<script>
import Navbar from "../../components/Header/Navbar";
import Footer from "../../components/Header/Footer";
export default {
  components: {
    Footer,
    Navbar,
  },
  data() {
    return {
      pages:1,
      is_counts:0,
      region_name:'Выберите регион',
      catename:'Выберите тип',
      isselcts:false,
      isselcts1:false,
      year:'2022',
      isActive_m: false,
      selected:'',
      selected1:'Dekabr',
      searchs:'',
      cat_id:'',
      reg_id:'',
      month:'',
      options: [
        { value: null, text: 'Please select some item' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Default Selected Option' },
        { value: 'c', text: 'This is another option' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ],
      options1: [
        { value: null, text: 'Please select some item' },
        { value: 'a', text: 'This is First option' },
        { value: 'b', text: 'Default Selected Option' },
        { value: 'c', text: 'This is another option' },
        { value: 'd', text: 'This one is disabled', disabled: true }
      ],
    }
  },
  mounted() {
    this.$store.dispatch('newsM')
    this.$store.dispatch('heritage')
    this.$store.dispatch('heritages_main1',this.pages)
    this.catename=this.$store.state.language.lang[143]
    this.region_name=this.$store.state.language.lang[144]
  },
  methods: {
    restoveratsiya(item){
      this.$store.dispatch('heritages_res',item)
    },
    clickCallback (pageNum){

      this.$store.dispatch('heritages_main',pageNum)
    },
    submits(){
      if(this.reg_id!='null'&&this.cat_id!='null') {
        this.$store.dispatch('heritages_main_searchs', {
          'name': this.searchs,
          'cat_id': this.cat_id,
          'reg_id': this.reg_id
        })
      }
      else {
        this.$store.dispatch('heritages_main',this.pages)
        this.catename=this.$store.state.language.lang[143]
      }
    },
    myCounts(items,index){
      this.is_counts=index
      this.pages=items
      this.$store.dispatch('heritages_main',this.pages)

    },
    openSelects(){
      this.isselcts=!this.isselcts
      this.isselcts1=false

    },
    search_cate(item){
      this.catename=item.title
      this.isselcts=!this.isselcts
      this.cat_id=item.id
    },
    region(item,index){
      this.region_name=item
      this.isselcts1=!this.isselcts1
      this.reg_id=index
    },
    openSelects1(){
      this.isselcts1=!this.isselcts1
      this.isselcts=false
    },
    allCate(){
      this.catename='все'
      this.isselcts=!this.isselcts
    },
    filter(){

      this.$store.dispatch('newsMfiltr',{
        "year":this.year,
        "month":this.month
      })
    }
  }
}
</script>
